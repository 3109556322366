import * as ROUTES from 'constants/routes'
import SignInContainer from 'containers/signIn/SignInContainer'
import NoLayout from 'providers/layout/NoLayout'
import MainLayout from 'providers/layout/MainLayout'
import CalendarContainer from 'containers/calendar/CalendarContainer'
import SettingContainer from 'containers/setting/SettingContainer'
import SettingProfileContainer from 'containers/setting/SettingProfile/SettingProfileContainer'
import ServicesContainer from 'containers/patients/services/ServicesContainer'
import EmptyLayout from 'providers/layout/EmptyLayout'
import PatientsFilesContainer from 'containers/patients/patientFiles/PatientsFilesContainer'
import PatientsContainer from '../containers/patients/PatientsContainer'
import PatientsDetailContainer from '../containers/patients/patientsDetail/PatientsDetailContainer'
import SettingPersonContainer from '../containers/setting/SettingPerson/SettingPersonContainer'
import SettingClinicsContainer from '../containers/setting/SettingClinics/SettingClinicsContainer'
import SettingRolesContainer from '../containers/setting/SettingRoles/SettingRolesContainer'
import SettingServicesContainer from '../containers/settingServices/SettingServicesContainer'
import ReportsContainer from '../containers/reports/ReportsContainer'
import SettingPassword from '../containers/setting/SettingPassword/SettingPassword'
import SmsContainer from '../containers/sms/SmsContainer'
import SettingCheckTemplatesContainer from '../containers/setting/SettingCheckTemplates/SettingCheckTemplatesContainer'
import SettingMessagesContainer from '../containers/setting/SettingMessagesTemplates/SettingMessagesContainer'

export const settingRoutes = [
  {
    path: ROUTES.SETTING_PROFILE_URL,
    exact: true,
    layout: EmptyLayout,
    component: SettingProfileContainer
  },
  {
    path: ROUTES.SETTING_PASSWORD_URL,
    layout: EmptyLayout,
    component: SettingPassword
  },
  {
    path: ROUTES.SETTING_CLINICS_URL,
    layout: EmptyLayout,
    component: SettingClinicsContainer
  },
  {
    path: ROUTES.SETTING_SMS_TEMPLATES_URL,
    layout: EmptyLayout,
    component: SettingMessagesContainer
  },
  {
    path: ROUTES.CHECK_MESSAGES_URL,
    layout: EmptyLayout,
    component: SettingCheckTemplatesContainer
  },
  {
    path: ROUTES.SETTING_ROLES_URL,
    layout: EmptyLayout,
    component: SettingRolesContainer
  },
  {
    path: ROUTES.SETTING_PERSON_URL,
    layout: EmptyLayout,
    component: SettingPersonContainer
  }
]

export default [
  {
    path: ROUTES.SIGN_IN_URL,
    layout: NoLayout,
    exact: true,
    component: SignInContainer
  },
  {
    path: ROUTES.ROOT_URL,
    layout: MainLayout,
    exact: true,
    component: SettingServicesContainer
  },
  {
    path: ROUTES.CALENDAR_URL,
    exact: true,
    layout: MainLayout,
    component: CalendarContainer
  },
  {
    path: ROUTES.PATIENTS_URL,
    exact: true,
    layout: MainLayout,
    component: PatientsContainer,
    routes: [
      {
        path: ROUTES.PATIENTS_QUESTIONNAIRE_PATH,
        exact: true,
        layout: EmptyLayout,
        component: ServicesContainer
      },
      {
        path: ROUTES.PATIENTS_DETAIL_FILES_PATH,
        exact: true,
        layout: MainLayout,
        component: PatientsFilesContainer
      },
      {
        path: ROUTES.PATIENTS_DETAIL_PATH,
        exact: true,
        layout: MainLayout,
        component: PatientsDetailContainer
      }
    ]
  },
  {
    path: ROUTES.SETTING_URL,
    exact: false,
    fill: true,
    layout: MainLayout,
    component: SettingContainer
  },
  {
    path: ROUTES.REPORTS_URL,
    exact: true,
    layout: MainLayout,
    component: ReportsContainer
  },
  {
    path: ROUTES.SMS_URL,
    exact: true,
    layout: MainLayout,
    component: SmsContainer
  },
]
