import React from 'react'
import styled from 'styled-components'
import {
  length,
  __, nth, partial, path, pipe, pluck, propEq, filter, map, sum
} from 'ramda'
import moment from 'moment'
import { numberFormat } from '../../../../utils/numberFormat'
import { getFullName } from '../../../../utils/get'
import { TTeethTable } from '../ServicesProvider'

const Container = styled.div<{length: number}>`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
    @media print {
        @page {
            size: ${({ length }) => `80mm ${160 + 10 * length}mm`};
        }
    }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Logo = styled.img`
  width: 100px;
  object-fit: contain;
  align-self: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Info = styled.div`
  text-align: left;
`

const Section = styled.div`
  margin: 20px 0;
`

const ServiceItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const Totals = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
`

const Line = styled('div')`
    border-top: 1px dashed #000;
    margin: 10px 0;
`
const ContactInfo = styled.div`
  margin-top: 30px;
`

const Table = styled.table`
 width: 100%;
    text-align: left;
`

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  white-space: pre-line;
`

const getRandomIndex = (arr) => Math.floor(Math.random() * length(arr))

const ServiceInvoice = ({ data, itemData, listMessagesData }) => {
  const onlyPaidData = data
  const message = pipe(
    path(['data', 'data']),
    pluck('body'),
    (bodies) => nth(getRandomIndex(bodies), bodies) || ''
  )(listMessagesData) as string
  const totalPrice = pipe(
    map((item: TTeethTable) => item.customPrice * item.amount),
    sum
  )(onlyPaidData)
  const dataDetail = path(['data', 'data'], itemData)

  const patientName = getFullName(dataDetail?.patient)

  const doctorName = getFullName(dataDetail?.doctor)
  const chequeLogo = path(['clinic', 'chequeLogo'], dataDetail)
  const address = path(['clinic', 'location', 'address'], dataDetail)
  const phoneNumber = path(['clinic', 'phoneNumber'], dataDetail)
  return (
    <Container length={onlyPaidData.length}>
      <Header>
        <Logo src={chequeLogo}/>
        <Info>
          <p>ДАТА: {moment().locale('ru').format('DD MMMM YYYY')}</p>
          <p>ВРЕМЯ: {moment().format('HH:mm')}</p>
        </Info>
      </Header>

      <Section>
        <p>ИМЯ ПАЦИЕНТА: {patientName}</p>
        <p>ВРАЧ: {doctorName}</p>
      </Section>

      <Section>
        <Line/>
        <Table className="table">
          <thead>
            <tr>
              <th>ДЕТАЛИ УСЛУГ</th>
              <th>К-во</th>
              <th>СУММА</th>
            </tr>
            <tr>
              <th colSpan={3}>
                <Line/>
              </th>
            </tr>
          </thead>
          <tbody>
            {onlyPaidData.map((item, index) => {
              const price = path(['cost'], item) * item.amount
              const service = path(['name'], item)

              return (
                <tr key={index}>
                  <td>{service}</td>
                  <td>{item.amount}</td>
                  <td>{numberFormat(price, 'сум')}</td>
                </tr>
              )
            })}
            <tr>
              <td colSpan={3}>
                <Line/>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                ИТОГОВАЯ СУММА:
              </td>
              <td>
                {numberFormat(totalPrice, 'сум')}
              </td>
            </tr>
          </tbody>
        </Table>
      </Section>

      <ContactInfo>
        <p>{address}</p>
        <p>Телефон: {phoneNumber}</p>
      </ContactInfo>

      <Footer>
        {message}
      </Footer>
    </Container>
  )
}

export default ServiceInvoice
