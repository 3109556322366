import * as stateNames from 'constants/states'
import React, { useEffect } from 'react'
import Layout from 'providers/layout/MainLayout'
import { useFetchItem, useFetchList } from '../../../hooks'
import { calendarDetailAction, calendarUpdateAction } from '../../calendar/actions'
import useUpdate from '../../../hooks/useUpdate'
import {
  servicesDiagnosisListAction,
  servicesListAction, servicesProcedureListAction
} from '../../settingServices/settingServicesActions/settingServicesActions'
import useModal from '../../../hooks/useModal'
import {
  checkTemplatesListAction
} from '../../setting/SettingCheckTemplates/settingCheckTemplatesActions/settingCheckTemplatesActions'
import TeethGrid from './components/ServicesGrid'
import { ServicesProvider } from './ServicesProvider'

const ServicesContainer = props => {
  const listMessagesData = useFetchList({
    action: checkTemplatesListAction,
    stateName: stateNames.CHECK_TEMPLATES_LIST
  })
  const itemData = useFetchItem<any>({
    action: calendarDetailAction,
    stateName: stateNames.CALENDAR_DETAIL
  })

  const updateData = useUpdate<any>({
    action: calendarUpdateAction,
    stateName: stateNames.CALENDAR_CREATE
  })

  const servicesData = useFetchList({
    action: servicesListAction,
    mapper: () => ({
      size: 1000,
      isMain: 1
    }),
    pickParams: [],
    stateName: stateNames.SERVICES_LIST
  })

  const servicesDiagnosisData = useFetchList({
    action: servicesDiagnosisListAction,
    mapper: () => ({
      size: 1000,
      type: 'diagnosis'
    }),
    pickParams: [],
    stateName: stateNames.SERVICES_DIAGNOSIS_LIST
  })

  const servicesProcedureData = useFetchList({
    action: servicesProcedureListAction,
    mapper: () => ({
      size: 1000,
      type: 'procedure'
    }),
    pickParams: [],
    stateName: stateNames.SERVICES_PROCEDURE_LIST
  })

  const servicesModalData = useModal({ })
  return (
    <Layout>
      <ServicesProvider itemData={itemData}>
        <TeethGrid
          servicesData={servicesData}
          itemData={itemData}
          servicesDiagnosisData={servicesDiagnosisData}
          servicesProcedureData={servicesProcedureData}
          updateData={updateData}
          servicesModalData={servicesModalData}
          listMessagesData={listMessagesData}
        />
      </ServicesProvider>
    </Layout>
  )
}

export default ServicesContainer
