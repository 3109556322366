import * as API from 'constants/api'
import * as ACTION_TYPES from 'constants/actionTypes'
import { CALENDAR_CONFIRM, CALENDAR_DETAIL, DOCTORS_LIST } from 'constants/api'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { sprintf } from 'sprintf-js'

export const calendarCreateAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.CALENDAR_CREATE, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_CREATE}`,
      payload
    })
  }
}

export const calendarUpdateAction = (id, params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .put(sprintf(API.CALENDAR_UPDATE, id), params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_CREATE}`,
      payload
    })
  }
}

export const calendarListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState }, false)
      .get(API.CALENDAR_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_LIST}`,
      payload
    })
  }
}

export const calendarDetailAction = (id) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState }, false)
      .get(sprintf(API.CALENDAR_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_DETAIL}`,
      payload
    })
  }
}

export const calendarDeleteAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .delete(sprintf(API.CALENDAR_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_DELETE}`,
      payload
    })
  }
}

export const calendarConfirmAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.CALENDAR_CONFIRM, { appointmentId: id })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_CONFIRM}`,
      payload
    })
  }
}

export const calendarDoctorsAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.DOCTORS_LIST, { params: { size: 100 } })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CALENDAR_DOCTORS}`,
      payload
    })
  }
}
