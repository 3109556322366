import React, { useState } from 'react'
import { append, remove } from 'ramda'
import styled from 'styled-components'
import { ButtonUI } from '../Button'
import InputTextUI from './InputTextUI'

const ImagePreview = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  margin-top: 20px;
`

const ImageContainer = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

const ImageUrlField = ({ input, meta, label }) => {
  const [url, setUrl] = useState('')
  const addImage = () => {
    const newImage = url
    if (newImage) {
      input.onChange(newImage) // Обновить значение поля в форме
      setUrl('') // Очистить поле ввода после добавления
    }
  }

  const removeImage = () => {
    input.onChange(null) // Обновить значение поля в форме
  }

  return (
    <div>
      <div className={'flex align-items-end'}>
        <div className={'w-full mr-3'}>
          <InputTextUI
            label={label}
            value={url}
            type="text"
            fullWidth={true}
            onChange={e => setUrl(e.target.value)}
            placeholder="Enter image URL"
          />
        </div>
        <ButtonUI type="button" onClick={addImage} style={{ height: '40px', width: 150 }} classNames={['flex', 'justify-content-center']}>Add Image</ButtonUI>
      </div>
      {meta.error && meta.touched && <span>{meta.error}</span>}
      {input.value && <ImagePreview>
        <ImageContainer>
          <RemoveButton onClick={() => removeImage()}>X</RemoveButton>
          <img src={input.value}/>
        </ImageContainer>
      </ImagePreview>}
    </div>
  )
}

export default ImageUrlField
