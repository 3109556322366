import { find, path, pipe, propEq } from 'ramda'
import { TPaymentTypes, TTeethStatuses } from '../types/enums'

export const TOP_BAR_HEIGHT = '90px'
export const BOTTOM_BAR_HEIGHT = '80px'

export const DEFAULT_PICK_PARAMS = ['search', 'page', 'ordering', 'pageSize']

export const UPLOAD_FILE_TYPES = {
  WORD: '.doc, .docx, application/msword',
  EXCEL: '.xls, .xlsx, application/vnd.ms-excel',
  TEXT: 'text/plain',
  PDF: '.pdf, application/pdf',
  IMAGES: 'image/jpg, image/jpeg, image/png',
  IMAGES_ALL: 'image/*',
}

// content types
export const CONTENT_TYPE_JSON = 'application/json'

// Title
export const LANGUAGES = [{ id: 'ru', name: 'Русский' }, { id: 'uz', name: 'Uzbekistan' }, { id: 'en', name: 'English' }]
export const USER_STATUS_LIST = [
  {
    _id: 'active',
    name: 'Активный',
  },
  {
    _id: 'blocked',
    name: 'Заблокированный',
  }
]

export const GENDERS = [
  {
    _id: 'm',
    name: 'Мужской',
  },
  {
    _id: 'f',
    name: 'Женский',
  }
]

export const STATUS_TEETH = [
  { _id: TTeethStatuses.ACTIVE, name: 'Активный' },
  { _id: TTeethStatuses.NOT_COMPLETED, name: 'Не выполнено' },
  { _id: TTeethStatuses.DONE, name: 'Выполнено' },
  { _id: TTeethStatuses.PAID, name: 'Оплачен' }
]

export const PAYMENT_TYPES = [
  { _id: TPaymentTypes.CASH, name: 'Наличные' },
  { _id: TPaymentTypes.POS, name: 'Терминал' },
  { _id: TPaymentTypes.P2P, name: 'P2P' },
]

export const getKeyFromConst = (arr, id, key = ['name']) => pipe(
  find(propEq(id, 'id')),
  path(key)
)(arr)

export const WEEKS = [
  { _id: 'Mo', name: 'Пн' },
  { _id: 'Tu', name: 'Вт' },
  { _id: 'We', name: 'Ср' },
  { _id: 'Th', name: 'Чт' },
  { _id: 'Fr', name: 'Пт' },
  { _id: 'Sa', name: 'Сб' },
  { _id: 'Su', name: 'Вс' }
]

export const GENDER = [
  { _id: 'male', name: 'Мужской' },
  { _id: 'женский', name: 'Женский' },
]

export const PERMISSION_KEYS = [
  {
    key: 'all',
    _id: '*',
    name: 'Полный доступ'
  },
  {
    key: 'read',
    _id: 'READ',
    name: 'Чтение'
  },
  {
    key: 'update',
    _id: 'UPDATE',
    name: 'Обновление'
  }
]

export const OPEN_NOTIFICATION = 'openNotification'

export const GRADIENTS = [
  'linear-gradient(to right, #f9d423 0%, #ff4e50 100%)',
  'linear-gradient(to top, #ff0844 0%, #ffb199 100%)',
  'linear-gradient(to top, #0250c5 0%, #d43f8d 100%)',
  'linear-gradient(to top, #f43b47 0%, #453a94 100%)',
  'linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)',
  'linear-gradient(to right, #f83600 0%, #f9d423 100%)',
  'linear-gradient(to top, #209cff 0%, #68e0cf 100%)',
  'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
  'linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)'
]

export const COLORS = [
  '#FF5733', '#33FF57', '#5733FF', '#FFD133', '#33D1FF', '#FF33A1',
  '#A133FF', '#33FFCC', '#FF6E33', '#8CFF33', '#336EFF', '#FF33D1',
  '#FF3333', '#33FF88', '#6E33FF', '#FFB533', '#33FFF6', '#FF33B5',
  '#D633FF', '#33FF99', '#FF333C', '#33FFC2', '#5733FF', '#FF334D',
  '#33FFA1', '#FF5733', '#33FFD7', '#FF33A9', '#B533FF', '#33FFBB',
  '#FF334F', '#33FFB2', '#FF33D9', '#8033FF', '#33FFAA', '#FF6E33',
  '#33FF77', '#FF33C2', '#5733FF', '#33FF5A', '#FF5733', '#33FF66',
  '#FF3399', '#A133FF', '#33FFAF', '#FF339F', '#33FFD1', '#FF5733',
  '#33FF33', '#FF33B2', '#5733FF', '#33FF55', '#FF336E', '#B533FF',
  '#33FF4D', '#FF339D', '#33FF44', '#FF33FF', '#5733FF', '#33FF38'
]
