import React from 'react'
import AppTopBar from 'components/AppTopBar'
import SearchUI from 'components/SearchUI/SearchUI'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { path, prop } from 'ramda'
import { sprintf } from 'sprintf-js'
import Container from '../../../components/Container'
import { UniversalSearchField, UniversalStaticSelectField } from '../../../components/Form/Select'
import * as API from '../../../constants/api'
import checkRole from '../../../utils/checkRole'
import DateUI from '../../../components/Form/DateUI'
import { getFullName } from '../../../utils/get'
import { ButtonUI } from '../../../components/Button'
import { DATE_BACKEND, DATE_FORMAT_ISO_8601 } from '../../../constants/dateFormat'
import * as STATES from '../../../constants/states'
import { DOCTORS_FOR_CLINIC_LIST } from '../../../constants/api'
import ReportStatusChart from './ReportStatusChart'
import PatientsTable from './PatientsTable'
import ReportDebtorsChart from './ReportDebtorsChart'
import { DoctorStatGrid } from './DoctorStatCard'

type Props = {
    listData: any
    statsData: any
    filterAction: any
}
const SimpleGrid = styled.div`
 display: grid;
  grid: 1fr/ 1fr 1fr;
  gap: 30px;
`

const StyledCont = styled(Container)`
  padding: 0px calc(35px - 3rem); 
  margin-bottom: 20px
`

const FullSize = styled.div`
  grid-column: 1/3;
  max-height:  80vh;
  overflow: auto;
`
function isValidDate (d) {
  // @ts-ignore
  return d instanceof Date && !isNaN(d)
}
export const fields = ['clinicId', 'startDate', 'endDate', 'doctorId']
const ReportsGrid = ({ listData, statsData, filterAction }: Props) => {
  const profile = useSelector(prop(STATES.PROFILE))
  const data = path(['data', 'data'], profile)
  const initialValues = {
    ...filterAction.initialValues,
    clinicId: filterAction.initialValues?.clinicId || path(['clinicIds', '0'], data),
    date: [
      moment(filterAction.initialValues?.startDate, DATE_BACKEND).toDate(),
      moment(filterAction.initialValues?.endDate, DATE_BACKEND).toDate()
    ]
  }
  return (
    <div>
      <Form
        onSubmit={(values) => {
          return filterAction.onSubmit({ ...values, startDate: isValidDate(values?.date[0]) ? moment(values?.date[0]).format(DATE_FORMAT_ISO_8601) : null, endDate: isValidDate(values?.date[1]) ? moment(values?.date[1]).format(DATE_FORMAT_ISO_8601) : null })
        }}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, values, form }) => {
          const clinicId = values?.clinicId?.id || values?.clinicId
          return (
            <form onSubmit={handleSubmit}>
              <AppTopBar
                title={'Отчеты'}
                leftActions={(
                  <div className="flex ">
                    <div className={'w-18rem'}>
                      <Field
                        name="clinicId"
                        api={API.CLINICS_LIST}
                        component={UniversalSearchField}
                        placeholder={'Клиника'}
                        typeSelect={'simple'}
                        onChange={(value) => {
                          form.change('doctorId', null)
                          filterAction.onSubmit({ clinicId: value?._id, doctorId: null })
                        }}
                      />
                    </div>
                  </div>
                )}
                rightActions={(
                  <div className="flex ">
                    <div className={'mr-2'}>
                      <Field
                        required
                        component={DateUI}
                        name={'date'}
                        selectionMode="range"
                        readOnlyInput
                        hideOnRangeSelection
                        placeholder={'Дата'}
                        numberOfMonths={2}
                      />
                    </div>
                    {clinicId && <div className={'mr-2 w-18rem'}>
                      <Field
                        required
                        name="doctorId"
                        typeSelect={'simple'}
                        parent={clinicId}
                        api={sprintf(API.DOCTORS_FOR_CLINIC_LIST, clinicId)}
                        itemText={['email']}
                        placeholder={'Доктор'}
                        getText={(obj) => getFullName(obj)}
                        component={UniversalSearchField}
                      />
                    </div>}
                    <div>
                      <ButtonUI>
                        Применить
                      </ButtonUI>
                    </div>
                  </div>
                )}
              />
            </form>
          )
        }}
      />

      <StyledCont>
        <SimpleGrid>
          <div className={'card mb-0'}>
            <ReportDebtorsChart listData={listData}/>
          </div>
          <div className={'card mb-0'}>
            <ReportStatusChart statsData={statsData}/>
          </div>
          <DoctorStatGrid statsData={statsData} listData={listData}/>
          <FullSize>
            <PatientsTable listData={listData} statsData={statsData} initialValues={initialValues} filterAction={filterAction}/>
          </FullSize>
        </SimpleGrid>
      </StyledCont>
    </div>
  )
}

export default ReportsGrid
