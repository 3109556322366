import React, { ReactElement, ReactNode, useEffect } from 'react'
import { getCookie } from 'utils/cookie'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import * as ROUTES from '../../constants/routes'
import { ProfileParagraf } from '../Text'
import checkPermissions from '../../utils/checkPermissions'
import { useTypedSelector } from '../../etc/reducers'
import { getDataFromState } from '../../utils/get'
import * as STATE from '../../constants/states'
import checkRole from '../../utils/checkRole'

interface Props {
  children: ReactNode
  permissions?: string[]
  roles?: string[]
}

function PermissionWrapper (props: Props): ReactElement {
  const { children, permissions, roles = [] } = props

  const hasPerms = checkPermissions(permissions)
  const hasRoles = checkRole(roles)
  const history = useHistory()
  const profile = useTypedSelector(state => getDataFromState(STATE.PROFILE, state))
  const auth = !!(getCookie('token'))

  useEffect(() => {
    if (!auth) {
      history.replace(ROUTES.SIGN_IN_URL)
    }
  }, [])
  if (auth && hasPerms && isEmpty(roles)) {
    return (
      <div>
        {children}
      </div>
    )
  } else if (auth && hasRoles && !isEmpty(roles)) {
    return (
      <div>
        {children}
      </div>
    )
  }

  return (
    <div className={'min-h-screen flex align-content-center justify-content-center'} style={{ paddingBottom: '80px' }}>
      <div className={'flex align-content-center flex-column justify-content-center'}>
        <h3>
          У вас недостаточно прав
        </h3>
        <ProfileParagraf className={['text-center']}>Обратитесь к администратору</ProfileParagraf>
      </div>
    </div>
  )
}

PermissionWrapper.defaultProps = {
  hasPermission: true
}

export default PermissionWrapper
