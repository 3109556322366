import moment from 'moment-timezone'
import 'moment/locale/ru'

export const monthsArr = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']

export const momentConfig = (): void => {
  moment.locale('ru')
  moment.updateLocale('ru', {
    monthsShort: monthsArr
  })
  moment.tz.setDefault('Asia/Tashkent')
}
