import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  TAppointmentsItem,
  TGetDataFromState,
  TUseCreateModal,
  TUseDelete,
  TUseUpdateModal
} from '../../../types'
import { useCalendarContext } from '../calendar-provider'
import { setToLocalStorageFilters } from '../utils'
import Calendar from './Calendar'

type Props = {
  createData: TUseCreateModal<TAppointmentsItem>
  listData: TGetDataFromState<any>
  deleteData: TUseDelete
  confirmData: TUseDelete
  updateData: TUseUpdateModal<any>
  gotDetail: (id) => void
  doctor?: string | undefined
  isDirector?: boolean
  personListData?: any
}
const CalendarGrid = (props: Props) => {
  const { state } = useCalendarContext()
  const { pathname } = useLocation()

  useEffect(() => {
    setToLocalStorageFilters(
      {
        currentDate: state.currentDate,
        view: state.view,
        dateRange: state.dateRange,
      },
      pathname
    )
  }, [state.currentDate, state.view])
  return (
    <Calendar {...props} />
  )
}

export default CalendarGrid
