import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingCheckTemplatesCreateDrawer from './SettingCheckTemplatesCreateDrawer'
import SettingCheckTemplatesTable from './SettingCheckTemplatesTable'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingCheckTemplatesGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <SettingCheckTemplatesTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingCheckTemplatesCreateDrawer
        {...createDrawer}
      />
      <SettingCheckTemplatesCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingCheckTemplatesGrid
