import moment from 'moment-timezone'

type FilterDateOptions = {
  allowSameDay?: boolean
}

export function filterPastDate (date: Date): boolean {
  const currentDate = moment.tz('Asia/Tashkent')
  const selectedDate = moment.tz(date, 'Asia/Tashkent')
  const isToday = selectedDate.isSame(currentDate, 'day')
  return currentDate.isBefore(selectedDate, 'day') || isToday
}

export function filterPastTime (time: Date): boolean {
  const currentDate = new Date()
  const selectedDate = new Date(time)

  return currentDate.getTime() < selectedDate.getTime()
}

export function filterSelectedEndDate (startDate: Date, endDate: Date, options: FilterDateOptions = {}): boolean {
  const { allowSameDay = false } = options

  if (allowSameDay && moment(startDate).isSame(endDate, 'day')) return true

  return moment(startDate).isAfter(endDate)
}
