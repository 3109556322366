import * as API from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextAreaUI, InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { useStore } from 'react-redux'
import { UniversalSearchField } from '../../../../components/Form/Select'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

export const fields = [
  'name',
  'body',
  'clinicId',
]
const SettingMessagesCreateDrawer = (props: Props) => {
  const { open, onClose, onSubmit, loading, initialValues } = props

  const store = useStore()
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="p-fluid">
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'name'}
                    label={'Название'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={InputTextAreaUI}
                    name={'body'}
                    label={'Текст сообщения'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Клиника"
                    name="clinicId"
                    api={API.CLINICS_LIST}
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <DrawerBottomActions>
                  <ButtonUI
                    loading={loading}
                    severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                  </ButtonUI>
                </DrawerBottomActions>
              </div>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SettingMessagesCreateDrawer
