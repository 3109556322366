import * as API from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import { fieldNormalizeParams } from 'utils/normalize'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { UniversalSearchField } from '../../../components/Form/Select'
import { UPLOAD_FILE_TYPES } from '../../../constants/constants'
import ImageUploadField from '../../../components/ImageUpload/ImageUploadField'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

export const fields = [
  'name',
  'note',
  'parentId',
  'imageUrl',
  'cost',
  'clinicId'
]

const serviceAcceptFiles = `${UPLOAD_FILE_TYPES.IMAGES}`

const SettingServicesCreateDrawer = (props: Props) => {
  const { open, onClose, onSubmit, loading, initialValues } = props
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="p-fluid">
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'name'}
                    required
                    label={'Название'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Родительская услуга"
                    name="parentId"
                    api={API.TREATMENTS_LIST}
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'note'}
                    label={'Заметка'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'cost'}
                    required
                    label={'Цена'}
                    {...fieldNormalizeParams}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Клиника"
                    name="clinicId"
                    api={API.CLINICS_LIST}
                    required
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Картинка"
                    name="imageUrl"
                    component={ImageUploadField}
                  />
                </FieldWrapper>
                <DrawerBottomActions>
                  <ButtonUI
                    loading={loading}
                    severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                  </ButtonUI>
                </DrawerBottomActions>
              </div>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SettingServicesCreateDrawer
