import React from 'react'
import { Column } from 'primereact/column'
import TableUI from 'components/Table/TableUI'
import styled from 'styled-components'
import {
  all,
  flatten,
  pathOr,
  pipe,
  propEq,
  ifElse,
  isEmpty,
  map,
  path,
  find,
  propOr,
  filter,
  propSatisfies,
  includes,
  __,
  groupBy, values
} from 'ramda'
import { Tag } from 'primereact/tag'
import { numberFormat } from '../../../../utils/numberFormat'
import { useFetchItem, useRedirect } from '../../../../hooks'
import * as stateNames from '../../../../constants/states'
import { patientsCalendarListAction } from '../../patientsActions'
import { PATIENTS_QUESTIONNAIRE_URL } from '../../../../constants/routes'
import dateFormat from '../../../../utils/dateFormat'
import { statusColor, TTeethStatuses } from '../../../../types/enums'
import { PAYMENT_TYPES, STATUS_TEETH } from '../../../../constants/constants'
import sumBy from '../../../../utils/sumBy'
import { scrollHeightBase } from '../../../../components/Table/utils'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const PatientsTable = ({}) => {
  const itemData = useFetchItem<any>({
    action: patientsCalendarListAction,
    stateName: stateNames.PATIENTS_CALENDAR_LIST
  })
  const gotDetail = useRedirect(PATIENTS_QUESTIONNAIRE_URL)

  const value = pathOr([], ['data', 'data'], itemData)

  const statusBody = (rowData) => {
    const teeth = pathOr([], ['teeth'], rowData)
    // @ts-ignore
    const statuses = ifElse(
      isEmpty,
      () => [],
      pipe(
        // @ts-ignore
        map((obj) => obj.services),
        flatten,
        groupBy(service => service.status),
        values,
      ),
    )(teeth)
    return (
      <div className={'flex '}>
        {isEmpty(statuses) ? 'Нет процедур' : statuses.map((arr, key) => {
          const status = path(['status'], arr[0])

          const statusText = pipe(
            find(propEq(status, '_id')),
            propOr('Сохранен', 'name')
          )(STATUS_TEETH) as string
          return (
            <Tag severity={statusColor(status)} value={`${statusText} - ${arr.length}`} style={{ whiteSpace: 'nowrap' }} key={key} className={'m-2'}/>
          )
        })}
      </div>
    )
  }

  const paymentType = (rowData) => {
    const teeth = pathOr([], ['teeth'], rowData)
    // @ts-ignore
    const paymentTypes = ifElse(
      isEmpty,
      () => [],
      pipe(
        map((obj: any) => obj.services),
        flatten,
        filter((service: any) => Boolean(service.paymentType)),
        groupBy(service => service.paymentType),
        values,
      ),
    )(teeth)
    return (
      <div className={'flex '}>
        {isEmpty(paymentTypes) ? 'Нет оплат' : paymentTypes.map((arr, key) => {
          const paymentType = path(['paymentType'], arr[0])

          const paymentTypeText = pipe(
            find(propEq(paymentType, '_id')),
            propOr('', 'name'),
          )(PAYMENT_TYPES) as string
          return (
            <Tag severity={'success'} value={paymentTypeText} style={{ whiteSpace: 'nowrap' }} key={key} className={'m-2'}/>
          )
        })}
      </div>
    )
  }

  const sumBody = (rowData, statuses) => {
    const teeth = pathOr([], ['teeth'], rowData)
    // @ts-ignore
    const services = ifElse(
      isEmpty,
      () => [],
      pipe(
        // @ts-ignore
        map((obj) => obj.services),
        flatten,
        filter(
          propSatisfies(includes(__, statuses), 'status'),
        )
      ),
    )(teeth)
    return numberFormat(sumBy('cost', services), 'сум')
  }
  return (
    <TableUI
      rowHover={true}
      lazy={false}
      loading={itemData.loading}
      paginator
      sortField="date" sortOrder={-1}
      scrollHeight={scrollHeightBase}
      rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
      onRowClick={({ data }) => gotDetail(data._id)}
      value={value}
    >
      <Column field="note" header="Заметка" />
      <Column header="Дата" field={'date'} sortable body={(item) => dateFormat(item.date)}/>
      <Column field="startTime" header="Начало" />
      <Column field="endTime" header="Конец" />
      <Column field="endTime" header="Долг" body={(rowData) => sumBody(rowData, [TTeethStatuses.DONE, TTeethStatuses.ACTIVE])}/>
      <Column field="endTime" header="Тип оплаты" body={paymentType}/>
      <Column field="endTime" header="Оплачено" body={(rowData) => sumBody(rowData, [TTeethStatuses.PAID])}/>
      <Column field="endTime" header="Статус" body={statusBody}/>
    </TableUI>
  )
}

export default PatientsTable
