import * as stateNames from 'constants/states'
import * as actionTypes from 'constants/actionTypes'
import createThunkReducer from 'utils/createThunkReducer'

export default {
  [stateNames.SERVICES_CREATE]: createThunkReducer(actionTypes.SERVICES_CREATE),
  [stateNames.SERVICES_LIST]: createThunkReducer(actionTypes.SERVICES_LIST),
  [stateNames.SERVICES_DIAGNOSIS_LIST]: createThunkReducer(actionTypes.SERVICES_DIAGNOSIS_LIST),
  [stateNames.SERVICES_PROCEDURE_LIST]: createThunkReducer(actionTypes.SERVICES_PROCEDURE_LIST),
}
