// Root
export const DOMEN = 'https://aqltish.uz'
export const ROOT_URL = `${DOMEN}/api`
const MAIN = 'main'

// Auth
const AUTH = 'auth'
export const SIGN_IN = `/${AUTH}/login/`

const USER = 'user'
export const PROFILE = `/${AUTH}/profile/`
export const USERS = 'users'
export const USERS_LIST = `/${USERS}/`
export const USERS_AVATAR = 'users/avatar/%s'
export const DOCTORS_LIST = `/${USERS}/doctors/`
export const DOCTORS_FOR_CLINIC_LIST = '/users/doctors/clinic/%s'
export const USERS_CREATE = `/${USERS}/`
export const USERS_UPDATE = `/${USERS}/%s/`
export const USERS_DELETE = `/${USERS}/%s/`

// File
export const FILE = 'file'
export const FILE_UPLOAD = '/upload'
export const FILE_STATIC = `${DOMEN}/uploads`
export const PATIENTS = 'patients'
export const PATIENTS_CREATE = `/${PATIENTS}/`
export const PATIENTS_UPDATE = `/${PATIENTS}/%s/`
export const PATIENTS_DETAIL = `/${PATIENTS}/%s/`
export const PATIENTS_DELETE = `/${PATIENTS}/%s/`
export const PATIENTS_LIST = `/${PATIENTS}/`

export const ROLES = 'roles'
export const ROLES_LIST = `/${ROLES}/`
export const ROLES_CREATE = `/${ROLES}/`
export const ROLES_UPDATE = `/${ROLES}/%s/`
export const ROLES_DELETE = `/${ROLES}/%s/`

export const SMS_TEMPLATES = 'sms-templates'
export const SMS_TEMPLATES_LIST = `/${SMS_TEMPLATES}/`
export const SMS_TEMPLATES_CREATE = `/${SMS_TEMPLATES}/`
export const SMS_TEMPLATES_UPDATE = `/${SMS_TEMPLATES}/%s/`
export const SMS_TEMPLATES_DELETE = `/${SMS_TEMPLATES}/%s/`

export const CHECK_TEMPLATES = 'cheque-templates'
export const CHECK_TEMPLATES_LIST = `/${CHECK_TEMPLATES}/`
export const CHECK_TEMPLATES_CREATE = `/${CHECK_TEMPLATES}/`
export const CHECK_TEMPLATES_UPDATE = `/${CHECK_TEMPLATES}/%s/`
export const CHECK_TEMPLATES_DELETE = `/${CHECK_TEMPLATES}/%s/`

export const CLINICS = 'clinics'
export const CLINICS_CREATE = `/${CLINICS}/`
export const CLINICS_UPDATE = `/${CLINICS}/%s/`
export const CLINICS_DELETE = `/${CLINICS}/%s/`
export const CLINICS_LIST = `/${CLINICS}/`

export const CALENDAR = 'appointments'
export const CALENDAR_CREATE = `/${CALENDAR}/`
export const CALENDAR_UPDATE = `/${CALENDAR}/%s/`
export const CALENDAR_DELETE = `/${CALENDAR}/%s/`
export const CALENDAR_CONFIRM = `/${CALENDAR}/approve/`
export const CALENDAR_DETAIL = `/${CALENDAR}/%s/`
export const CALENDAR_LIST = `/${CALENDAR}/`

export const TREATMENTS = 'treatments'
export const TREATMENTS_CREATE = `/${TREATMENTS}/`
export const TREATMENTS_UPDATE = `/${TREATMENTS}/%s/`
export const TREATMENTS_DELETE = `/${TREATMENTS}/%s/`
export const TREATMENTS_LIST = `/${TREATMENTS}/`

export const SMS = 'sms'
export const SMS_CREATE = `/${SMS}/`
export const SMS_UPDATE = `/${SMS}/%s/`
export const SMS_DELETE = `/${SMS}/%s/`
export const SMS_LIST = `/${SMS}/`

export const PERMISSIONS = 'permissions'
export const PERMISSIONS_LIST = `/${PERMISSIONS}/`

export const DEBTS_LIST = '/appointments/debts'
export const STATS_LIST = '/appointments/stats'

export const REGIONS = '/clinics/regions/'
export const REPORT_EXCEL = '/appointments/xls/'

export const DISTRICTS = '/clinics/districts/'
