import React from 'react'
import { filter, groupBy, isEmpty, join, omit, pathOr } from 'ramda'
import { TGetDataFromState, TTeethService, TUseModal } from 'types'
import { Menubar } from 'primereact/menubar'
import styled from 'styled-components'
import classNames from 'classnames'
import { useServices } from '../../ServicesProvider'
import { ButtonUI } from '../../../../../components/Button'

interface Props {
  servicesData: TGetDataFromState<{ data: TTeethService[] }>
  serviceSelected: TTeethService | null
  servicesModalData: TUseModal
  setSelectedService: (service: TTeethService | null) => void
}

const Styled = styled.div`
   & img {
     margin-right: 10px;
     height: 20px;
   };
  & .p-menu-separator {
    margin: 0 !important;
  }
`

const TeethService = (props: Props) => {
  const { serviceSelected, setSelectedService, servicesData, servicesModalData } = props
  const { teethSelected, handleAddServiceTeeth } = useServices()
  const isDiagnosis = serviceSelected?.type === 'diagnosis'
  const services: TTeethService[] = pathOr([], ['data', 'data'], servicesData).map(item => ({
    ...item,
    parentId: item?.parentId || null
  }))
  function childrenOf (partenId) {
    const grouped = groupBy(product => product.parentId, services)
    return (grouped[partenId] || []).map(product => ({
      ...product
    }))
  }
  const itemsService = (_id) => {
    return childrenOf(_id).map((item) => {
      const childrenId = item._id
      const childrenItems = itemsService(childrenId)
      const hasChildren = !isEmpty(childrenItems)
      return omit(hasChildren ? [] : ['items'], {
        label: item.name,
        icon: <img src={item.imageUrl} alt={''}/>,
        items: childrenItems,
        command: hasChildren ? null : (event) => {
          setSelectedService(null)
          handleAddServiceTeeth(item)
        }
      })
    })
  }
  const items = itemsService(serviceSelected?._id)
  const end = (
    <ButtonUI onClick={servicesModalData.onOpen}>
        + {isDiagnosis ? 'Все диагнозы' : 'Все процедуры'}
    </ButtonUI>
  )
  return (
    <div
      className={classNames({
        'card m-0': true,
        'p-0': serviceSelected
      })}
    >
      {serviceSelected ? (
        <Styled>
          <Menubar model={items} end={end}/>
        </Styled>
      ) : (
        <div className={'text-primary font-semibold'}>
          {isEmpty(teethSelected) ? 'Выберите зуб' : `Выбранные зубы - ${join(', ', teethSelected)}`}
        </div>
      )}
    </div>
  )
}

export default TeethService
