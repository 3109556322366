export enum TTeethTypes {
  MOLARS = 'molars',
  BABY = 'baby',
}

export enum TPaymentTypes {
  CASH = 'cash',
  POS = 'pos',
  P2P = 'p2p',
}

export enum TTeethStatuses {
  SAVED = 'saved', // as pending for backend
  PENDING = 'pending',
  SELECTED = 'selected',
  DELETED = 'deleted',
  ACTIVE = 'active',
  NOT_COMPLETED = 'not_completed',
  DONE = 'done',
  PAID = 'paid'
}

export const statusColor = type => {
  switch (type) {
  case TTeethStatuses.SAVED: return 'success'
  case TTeethStatuses.PENDING: return 'info'
  case TTeethStatuses.SELECTED: return 'info'
  case TTeethStatuses.DELETED: return 'danger'
  case TTeethStatuses.ACTIVE: return 'success'
  case TTeethStatuses.NOT_COMPLETED: return 'warning'
  case TTeethStatuses.DONE: return 'info'
  case TTeethStatuses.PAID: return 'danger'
  }
}
