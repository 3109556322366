import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { path, prop } from 'ramda'
import moment from 'moment'
import * as stateNames from '../../constants/states'
import { getDaysDifference } from '../../utils/date'
import { TProfile } from '../../types'

const Card = styled.div`
  border: 1px solid #C0C0C0;
  padding: 33px;
`
const SubscriptionBlock = props => {
  const profile = useSelector(prop(stateNames.PROFILE))
  const data = path<TProfile>(['data', 'data'], profile)
  const unBlockedDate = prop('unBlockedDate', data)
  const createdAt = prop('createdAt', data)
  const status = prop('status', data)

  const dayX = moment(unBlockedDate || createdAt).add(30, 'days')
  const blockDays = getDaysDifference(moment(), moment(dayX).format('YYYY-MM-DDT00:00:00'))
  return (
    <Card className={'text-xl'}>
      <p className="font-medium">До истичение срока</p>
      <p className={blockDays <= 3 ? 'text-red-500' : 'text-400'}>{blockDays} дн.</p>
    </Card>
  )
}

export default SubscriptionBlock
