// Error

export const ERROR = 'ERROR'
export const ERROR_OPEN = `${ERROR}_OPEN`
export const ERROR_CLOSE = `${ERROR}_CLOSE`

// SignIn
export const SIGN_IN = 'SIGN_IN'
export const PROFILE = 'PROFILE'
export const PROFILE_AVATAR = 'PROFILE_AVATAR'
export const PATIENTS_CREATE = 'PATIENTS_CREATE'
export const PATIENTS_LIST = 'PATIENTS_LIST'
export const PATIENTS_DETAIL = 'PATIENTS_DETAIL'
export const PATIENTS_CALENDAR_LIST = 'PATIENTS_CALENDAR_LIST'
export const PATIENTS_DELETE = 'PATIENTS_DELETE'

export const PERSON_CREATE = 'PERSON_CREATE'
export const PERSON_LIST = 'PERSON_LIST'
export const PERSON_DELETE = 'PERSON_DELETE'

export const CLINICS_CREATE = 'CLINICS_CREATE'
export const CLINICS_LIST = 'CLINICS_LIST'
export const CLINICS_DELETE = 'CLINICS_DELETE'

export const SERVICES_CREATE = 'SERVICES_CREATE'
export const SERVICES_LIST = 'SERVICES_LIST'
export const SERVICES_DIAGNOSIS_LIST = 'SERVICES_DIAGNOSIS_LIST'
export const SERVICES_PROCEDURE_LIST = 'SERVICES_PROCEDURE_LIST'
export const SERVICES_DELETE = 'SERVICES_DELETE'

export const SMS_CREATE = 'SMS_CREATE'
export const SMS_LIST = 'SMS_LIST'
export const SMS_DELETE = 'SMS_DELETE'

export const DEBTS_LIST = 'DEBTS_LIST'
export const REPORT_STATS_LIST = 'REPORT_STATS_LIST'
export const ROLES_CREATE = 'ROLES_CREATE'
export const ROLES_LIST = 'ROLES_LIST'
export const ROLES_DELETE = 'ROLES_DELETE'

export const SMS_TEMPLATES_CREATE = 'SMS_TEMPLATES_CREATE'
export const SMS_TEMPLATES_LIST = 'SMS_TEMPLATES_LIST'
export const SMS_TEMPLATES_DELETE = 'SMS_TEMPLATES_DELETE'

export const CHECK_TEMPLATES_CREATE = 'CHECK_TEMPLATES_CREATE'
export const CHECK_TEMPLATES_LIST = 'CHECK_TEMPLATES_LIST'
export const CHECK_TEMPLATES_DELETE = 'CHECK_TEMPLATES_DELETE'

export const CALENDAR_CREATE = 'CALENDAR_CREATE'
export const CALENDAR_CONFIRM = 'CALENDAR_CONFIRM'
export const CALENDAR_DOCTORS = 'CALENDAR_DOCTORS'
export const CALENDAR_LIST = 'CALENDAR_LIST'
export const CALENDAR_DETAIL = 'CALENDAR_DETAIL'
export const CALENDAR_DELETE = 'CALENDAR_DELETE'

// SignUp
export const SIGN_UP = 'SIGN_UP'
export const LOGIN = 'LOGIN'

// Snackbar
export const SNACKBAR_OPEN = 'SNACKBAR_OPEN'
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE'
