import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import LabeledValue from 'components/Detail/LabeledValue'
import FlexBorderBottom from 'components/Detail/FlexBorderBottom'
import { find, path, propEq } from 'ramda'
import { getFullName } from '../../../../utils/get'
import dateFormat from '../../../../utils/dateFormat'
import { GENDERS } from '../../../../constants/constants'
import { normalizePhone } from '../../../../utils/normalize'

type Props = {
}
const PatientsLeftSide = ({ itemData }) => {
  const data = path(['data', 'data'], itemData)
  return (
    <Accordion activeIndex={0} style={{ overflowX: 'auto' }}>
      <AccordionTab header="Основная информация">
        <FlexBorderBottom>
          <LabeledValue label={'ФИО'}>
            {getFullName(data)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Номнр телефона'}>
            {normalizePhone(data?.phoneNumber)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Дата рождения'}>
            {dateFormat(data?.birthdate)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Доктор'}>
            {getFullName(data?.doctor)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Адрес'}>
            {data?.address}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Клиника'}>
            {data?.clinic?.name}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Дата создания'}>
            {dateFormat(data?.createdAt)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Пол'}>
            {find<any>(propEq(data?.gender, '_id'), GENDERS)?.name}
          </LabeledValue>
        </FlexBorderBottom>
      </AccordionTab>
    </Accordion>
  )
}

export default PatientsLeftSide
