import * as stateNames from 'constants/states'
import React, { useState } from 'react'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import AppTopBar from 'components/AppTopBar'
import { useCreateModal, useDelete, useFetchList, useRedirect } from 'hooks'
import { getFullName, getParamsFormHistory, getSerializedData } from 'utils/get'
import { find, findIndex, path, propEq } from 'ramda'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Carousel } from 'primereact/carousel'
import useUpdateModal from '../../hooks/useUpdateModal'
import { TAppointmentsItem } from '../../types'
import { PATIENTS_QUESTIONNAIRE_URL } from '../../constants/routes'
import useConfirm from '../../hooks/useConfirm'
import Button from '../../components/Button/Button'
import { addParamsRoute } from '../../utils/route'
import checkRole from '../../utils/checkRole'
import { COLORS } from '../../constants/constants'
import CalendarGrid from './components/CalendarGrid'
import {
  calendarConfirmAction,
  calendarCreateAction,
  calendarDeleteAction, calendarDoctorsAction,
  calendarListAction,
  calendarUpdateAction
} from './actions'
import { fields } from './components/CalendarCreateDrawer'
import { CalendarListProvider } from './calendar-provider'
import { getToLocalStorageFilters } from './utils'

function getLastDigit (number) {
  return Math.abs(number) % 10
}

const CalendarContainer = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const searchParams = getParamsFormHistory(history)
  const doctor = path(['doctor'], searchParams)
  const listData = useFetchList({
    action: calendarListAction,
    stateName: stateNames.CALENDAR_LIST
  })

  const createData = useCreateModal<TAppointmentsItem>({
    serializer: (values) => getSerializedData(fields, values),
    action: calendarCreateAction,
    stateName: stateNames.CALENDAR_CREATE,
  })

  const updateData = useUpdateModal({
    listData,
    action: calendarUpdateAction,
    stateName: stateNames.CALENDAR_CREATE,
    serializer: (values) => getSerializedData(fields, values),
    successAction: calendarListAction
  })

  const deleteData = useDelete({
    stateName: stateNames.CALENDAR_DELETE,
    action: calendarDeleteAction,
  })

  const confirmData = useConfirm({
    stateName: stateNames.CALENDAR_CONFIRM,
    action: calendarConfirmAction,
    successAction: calendarListAction
  })

  const personListData = useFetchList({
    action: calendarDoctorsAction,
    stateName: stateNames.CALENDAR_DOCTORS
  })
  const doctorDataList = personListData?.data?.data?.filter((obj) => find(propEq('doctor', 'name'), obj?.roles)) || []
  const isDirector = checkRole(['director', 'superadmin'])
  const gotDetail = useRedirect(PATIENTS_QUESTIONNAIRE_URL)
  const defaultValues = JSON.parse(getToLocalStorageFilters(pathname))
  return (
    <CalendarListProvider defaultValues={defaultValues}>
      <AppTopBar
        title={'Календарь'}
        rightActions={isDirector && <div className={'flex justify-content-end'} style={{ width: '700px' }}>
          <Carousel value={[{ _id: '', firstName: 'Все врачи' }, ...doctorDataList]} className={'w-full'} numScroll={1} numVisible={4} showIndicators={false} itemTemplate={(obj) => {
            const isActive = (doctor === obj?._id) || (!doctor && obj._id === '')
            return (
              <div className={'flex justify-content-center align-items-center h-full'} >
                <Button
                  className={'w-11'}
                  style={{
                    backgroundColor: COLORS[findIndex(propEq(obj?._id, '_id'), [{ _id: '', firstName: 'Все врачи' }, ...doctorDataList])],
                    color: '#fff',
                    border: 'none',
                    boxShadow: 'none'
                  }}
                  outlined={!isActive}
                  onClick={() => addParamsRoute({ doctor: obj?._id }, history)}
                  label={getFullName(obj)}
                  key={obj._id}
                />
              </div>
            )
          }} />
        </div>}
      />
      <PermissionWrapper permissions={['READ']}>
        <div className="card">
          <CalendarGrid
            personListData={personListData}
            doctor={doctor}
            gotDetail={gotDetail}
            isDirector={isDirector}
            confirmData={confirmData}
            createData={createData} listData={listData} deleteData={deleteData} updateData={updateData} />
        </div>
      </PermissionWrapper>
    </CalendarListProvider>
  )
}

export default CalendarContainer
