import * as stateNames from 'constants/states'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { useCreateModal, useDelete, useFetchList } from 'hooks'
import { getSerializedData } from 'utils/get'
import useUpdateModal from 'hooks/useUpdateModal'
import SettingCheckTemplatesGrid from './components/SettingCheckTemplatesGrid'
import {
  checkTemplatesCreateAction, checkTemplatesDeleteAction,
  checkTemplatesListAction,
  checkTemplatesUpdateAction
} from './settingCheckTemplatesActions/settingCheckTemplatesActions'
import { fields } from './components/SettingCheckTemplatesCreateDrawer'

const SettingCheckTemplatesContainer = props => {
  const listData = useFetchList({
    action: checkTemplatesListAction,
    stateName: stateNames.CHECK_TEMPLATES_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => getSerializedData(fields, values),
    action: checkTemplatesCreateAction,
    successAction: checkTemplatesListAction,
    stateName: stateNames.CHECK_TEMPLATES_CREATE,
  })
  const deleteData = useDelete({
    stateName: stateNames.CHECK_TEMPLATES_DELETE,
    action: checkTemplatesDeleteAction,
    successAction: checkTemplatesListAction
  })

  const updateData = useUpdateModal({
    listData,
    action: checkTemplatesUpdateAction,
    stateName: stateNames.CHECK_TEMPLATES_CREATE,
    serializer: (values) => getSerializedData(fields, values),
    successAction: checkTemplatesListAction,
    initialValues: (data) => {
      return {
        ...data
      }
    }
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingCheckTemplatesGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SettingCheckTemplatesContainer
