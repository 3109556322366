import { DATE_FORMAT_ISO_8601 } from 'constants/dateFormat'
import React, { useReducer, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru'
import styled from 'styled-components'

import moment from 'moment-timezone'
import { find, findIndex, omit, path, pathEq, prop, propEq } from 'ramda'
import { ProgressSpinner } from 'primereact/progressspinner'
import { TAppointmentsItem, TGetDataFromState, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import { ProgressBar } from 'primereact/progressbar'
import { Menu } from 'primereact/menu'
import { Tooltip } from 'primereact/tooltip'
import { filterPastDate } from '../../../utils/datePicker'
import { isDateInRange } from '../../../utils/date'
import { useCompareEffect } from '../../../hooks'
import { useTypedSelector } from '../../../etc/reducers'
import { getDataFromState, getFullName } from '../../../utils/get'
import * as STATE from '../../../constants/states'
import { useCalendarContext } from '../calendar-provider'
import { COLORS } from '../../../constants/constants'
import useCalendarActions from './useCalendarActions'
import CalendarCreateDrawer from './CalendarCreateDrawer'

interface Props {
  createData: TUseCreateModal<TAppointmentsItem>
  listData: TGetDataFromState<any>
  deleteData: TUseDelete
  confirmData: TUseDelete
  updateData: TUseUpdateModal<any>
  gotDetail: (id) => void
  doctor?: string | undefined
  isDirector?: boolean
  personListData?: TGetDataFromState<any>
}
export const createDateInitial = (date, time) => {
  return moment(date).format(DATE_FORMAT_ISO_8601) + 'T' + time + '+05:00'
}

const Wrapper = styled('div')`
  & .fc .fc-popover {
    z-index: 101!important;
  };
  & .fc-popover-body {
      max-height: 200px;
      overflow: auto;
  }
`

const EventDot = styled('div')`
  & .fc .fc-popover {
    z-index: 10!important;
  }
`
const Calendar = (props: Props) => {
  const {
    doctor,
    isDirector,
    personListData,
    gotDetail,
    createData, listData, deleteData,
    confirmData,
    updateData
  } = props
  const doctorDataList = personListData?.data?.data?.filter((obj) => find(propEq('doctor', 'name'), obj?.roles)) || []

  const profile = useTypedSelector(state => getDataFromState(STATE.PROFILE, state))
  const profileId = path(['data', 'data', '_id'], profile)
  const menu = useRef(null)
  const [loadingSpiner, setLoadingSpiner] = useState(false)
  const { commit, state } = useCalendarContext()
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>()
  const data = path(['data', 'data'], listData) || []
  const initialData = data.filter((obj) => {
    return doctor ? pathEq(doctor, ['doctor', '_id'], obj) : true
  })
    .filter((obj) => {
      return isDirector ? true : pathEq(profileId, ['doctor', '_id'], obj)
    })
    .map(item => {
      const date = prop('date', item)
      const startTime = prop('startTime', item)
      const endTime = prop('endTime', item)

      return {
        id: prop('_id', item),
        title: prop('note', item),
        start: createDateInitial(date, startTime),
        backgroundColor: COLORS[findIndex(propEq(item.doctorId, '_id'), doctorDataList) + 1],
        end: createDateInitial(date, endTime),
        allDay: false,
        extendedProps: omit(['_id', '__v', 'startTime', 'endTime', 'date', 'note'], item)
      }
    })

  const { handleEventChange, handleEventDelete } = useCalendarActions({
    state,
    calendarRef,
    commit
  })
  const handleEventClick = (event) => {
    menu.current.show(event.jsEvent)
    setTimeout(() => {
      commit({ OPStyles: { top: `${event.jsEvent.pageY}px`, left: `${event.jsEvent.pageX}px` }, activeEvent: event.event.id, activeEventData: event.event.extendedProps })
    }, 0)
  }
  const handleDateSelect = (selectInfo) => {
    menu.current.hide(true)
    createData.onOpen()
    commit({
      selectDateEvents: state.currentEvents.filter((i) => {
        return isDateInRange(i.start, i.end || i.start, selectInfo.start)
      }),
      initialValues: {
        date: moment(selectInfo.startStr).toDate(),
        startTime: moment(selectInfo.startStr).format('HH:mm'),
        endTime: moment(selectInfo.endStr).format('HH:mm'),
      }
    })
  }
  const handleEvents = (events) => {
    commit({
      currentEvents: events
    })
  }

  const items = [
    {
      label: 'Опции',
      items: [
        {
          label: 'Проосмотреть',
          icon: 'pi pi-arrow-up-right',
          command: () => gotDetail(state.activeEvent)
        },
        {
          label: 'Изменить запись',
          icon: 'pi pi-pencil',
          command: () => {
            // @ts-ignore
            const clickInfo = calendarRef?.current?.getApi().getEventById(state.activeEvent)
            commit({
              selectDateEvents: state.currentEvents.filter((i) => {
                return isDateInRange(i.start, i.end || i.start, clickInfo.start) && i.id !== state.activeEvent
              }),
              initialValues: {
                date: moment(clickInfo.startStr).toDate(),
                note: clickInfo.title,
                startTime: moment(clickInfo.startStr).format('HH:mm'),
                endTime: moment(clickInfo.endStr).format('HH:mm'),
                doctorId: clickInfo.extendedProps.doctorId,
                clinicId: clickInfo.extendedProps.clinicId,
                patientId: clickInfo.extendedProps.patientId,
              }
            })
            updateData.onOpen(state.activeEvent)
          }
        },
        state.activeEventData?.status === 'CREATED_BY_PATIENT' && {
          label: 'Подтвердить запись',
          icon: 'pi pi-check',
          command: () => confirmData.onSubmit(state.activeEvent)
        },
        {
          label: 'Удалить запись',
          icon: 'pi pi-times',
          command: () => handleEventDelete(state.activeEvent)
        }
      ].filter(Boolean)
    }
  ]
  useCompareEffect(() => {
    setLoadingSpiner(true)
    setTimeout(() => {
      setLoadingSpiner(false)
    }, 100)
  }, [doctor])
  return (
    <>
      {(listData.loading || loadingSpiner || personListData.loading) ? (
        <div className="flex justify-content-center h-30rem align-items-center">
          <ProgressSpinner/>
        </div>
      ) : (
        <div className="demo-app" >
          <Wrapper className="demo-app-main" style={{ height: 'calc(100vh - 80px - 90px - 60px)' }}>
            <FullCalendar
              selectAllow={(event) => {
                return filterPastDate(event.start)
              }}
              height={'100%'}
              locale={ruLocale}
              ref={calendarRef}
              eventMinHeight={42}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              eventContent={(eventContent) => renderEventContent(eventContent, {
                activeEvent: state.activeEvent,
                deleteData,
                doctorDataList,
                updateData,
              })}
              initialDate={state.view === 'dayGridMonth' ? moment(state.currentDate || moment(), 'MMMM YYYY').format(
                'YYYY-MM-DD'
              ) : moment(state?.dateRange?.startDate || moment()).format('YYYY-MM-DD')}
              datesSet={(args) => {
                const endDateMinusOne = moment(args?.view?.activeEnd)
                  .subtract(1, 'days')
                  .toDate()
                commit({
                  currentDate: args?.view?.title,
                  view: args?.view?.type,
                  dateRange: {
                    startDate: args?.view?.activeStart,
                    endDate: endDateMinusOne,
                  },
                })
              }}
              initialView={state.view}
              editable={true}
              displayEventEnd={true}
              selectable={true}
              longPressDelay={200}
              selectMirror={true}
              timeZone={'Asia/Tashkent'}
              eventAllow={(event, movingEvent) => {
                const loading = deleteData.loading || updateData.loading
                if (loading) {
                  return state.activeEvent !== movingEvent.id
                } return true
              }}
              dayMaxEvents={true}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false
              }}
              weekends={state.weekendsVisible}
              initialEvents={initialData} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect}
              eventClick={handleEventClick}
              eventChange={handleEventChange}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            />
          </Wrapper>
        </div>
      )}
      <Menu model={items} popup ref={menu} style={{ ...state.OPStyles }} id="popup_menu" />
      <CalendarCreateDrawer
        selectDateEvents={state.selectDateEvents}
        state={state} commit={commit} ref={calendarRef}
        open={createData.open}
        loading={createData.loading}
        onClose={createData.onClose}
        onSubmit={createData.onSubmit}
      />
      <CalendarCreateDrawer
        selectDateEvents={state.selectDateEvents}
        state={state} commit={commit} ref={calendarRef}
        onSubmit={updateData.onSubmit}
        open={updateData.open}
        isUpdate={true}
        loading={updateData.loading}
        onClose={updateData.onClose}
      />
    </>
  )
}

function renderEventContent (eventContent, { activeEvent, deleteData, updateData, doctorDataList }) {
  const loading = deleteData.loading || updateData.loading
  const colorForDoctor = COLORS[findIndex(propEq(eventContent?.event.extendedProps.doctorId, '_id'), doctorDataList) + 1]
  return (
    <>
      {eventContent.view.type !== 'dayGridMonth' && loading && eventContent.event.id === activeEvent ? (
        <ProgressBar mode="indeterminate" style={{ height: '2px', margin: '0px 0px 7px 0px' }} color={'#bbe8d1'}/>
      ) : (
        <>
          {loading && eventContent.event.id === activeEvent ? <ProgressSpinner style={{ width: '12px', height: '12px', margin: '8px' }}/> : (
            <EventDot className={`fc-daygrid-event-dot event-${eventContent.event.id}`} style={eventContent.view.type === 'dayGridMonth' ? { background: colorForDoctor, borderColor: colorForDoctor } : { background: 'transparent', borderColor: 'transparent' }}/>
          )}
        </>
      )}
      <div className={`fc-event-time event-${eventContent.event.id}`}>{eventContent.timeText}</div>
      <div className={`fc-event-title event-${eventContent.event.id}`} id={`event-${eventContent.event.id}`} style={{ color: eventContent.event.extendedProps?.status === 'CREATED_BY_PATIENT' ? 'red' : null }}>{eventContent.event.title} - {getFullName(eventContent.event.extendedProps?.patient)}</div>
      <Tooltip target={`.event-${eventContent.event.id}`} content={getFullName(eventContent.event.extendedProps.doctor)} position="top" />
    </>
  )
}

export default Calendar
