import * as API from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { pathOr, prop } from 'ramda'
import moment from 'moment/moment'
import { GENDERS, UPLOAD_FILE_TYPES } from '../../../constants/constants'
import { getDataFromState, getFullName } from '../../../utils/get'
import { InputSwitchUi } from '../../../components/Form/Switcher'
import DateUI from '../../../components/Form/DateUI'
import UniversalStaticSelectField from '../../../components/Form/Select/UniversalStaticSelectField'
import UniversalMultiSelectField from '../../../components/Form/Select/UniversalMultiSelectField'
import { UniversalSearchField } from '../../../components/Form/Select'
import { DATE_BACKEND, DATE_FORMAT_ISO_8601 } from '../../../constants/dateFormat'
import AutoCompleteUI from '../../../components/Form/AutoCompleteUI'
import { trimString } from '../../../utils/trimString'
import { useFetchList } from '../../../hooks'
import * as stateNames from '../../../constants/states'
import {
  smsTemplatesListAction
} from '../../setting/SettingMessagesTemplates/settingMessagesActions/settingMessagesActions'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

export const fields = [
  'name',
  'body',
  'clinicId',
  'dates',
  'time',
  'isAllPatients',
  'gender',
  'patients',
]

const SmsCreateDrawer = (props: Props) => {
  const { open, onClose, onSubmit, loading, initialValues } = props

  const smsTemplate = useFetchList({
    action: smsTemplatesListAction,
    stateName: stateNames.SMS_TEMPLATES_LIST,
    mapper: () => ({ size: 100 })
  })
  const data = pathOr([], ['data', 'data'], smsTemplate)
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={(values) => onSubmit({ ...values, dates: (values.dates || []).map(i => moment(i).format(DATE_BACKEND)) })}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, values }) => {
          const isAll = prop('isAllPatients', values)
          return (
            <form onSubmit={handleSubmit}>
              <div className="p-fluid">
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'name'}
                    label={'Название'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={AutoCompleteUI}
                    items={data.map(i => ({ label: trimString(i.body, 120), value: i.body }))}
                    name={'body'}
                    rows={5}
                    loading={smsTemplate.loading}
                    label={'Текст сообщения'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    required
                    minDate={new Date()}
                    numberOfMonths={2}
                    selectionMode={'multiple'}
                    component={DateUI}
                    name={'dates'}
                    label={'Даты оповещения'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'time'}
                    label={'Время оповещения'}
                    type={'time'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Клиника"
                    name="clinicId"
                    api={API.CLINICS_LIST}
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Все пациенты"
                    name="isAllPatients"
                    type="checkbox"
                    defaultValue={false}
                    component={InputSwitchUi}
                  />
                </FieldWrapper>
                {isAll ? (
                  <FieldWrapper>
                    <Field
                      label="Гендер"
                      name="gender"
                      list={GENDERS}
                      component={UniversalStaticSelectField}
                    />
                  </FieldWrapper>
                ) : (
                  <FieldWrapper>
                    <Field
                      label="Пациенты"
                      name="patients"
                      api={API.PATIENTS_LIST}
                      component={UniversalMultiSelectField}
                      getText={getFullName}
                    />
                  </FieldWrapper>
                )}

                <DrawerBottomActions>
                  <ButtonUI
                    loading={loading}
                    severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                  </ButtonUI>
                </DrawerBottomActions>
              </div>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SmsCreateDrawer
